import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import heroImg from "../assets/bckgd-img/HeroSectionBckgd.jpeg";
import featureImg from "../assets/landing-page/features-connections.png";
import supportImg from "../assets/landing-page/xoc-currency.jpeg";
import learnImg from "../assets/card-images/connection.png";
import connectImg from "../assets/card-images/contribute.png";
import earnImg from "../assets/card-images/team-building.png";

const Landing = () => {
  return (
    <Fragment>
      <HeroContainer>
        <div className="hero-copy-container">
          <h1 className="uk-text-bold">
          Fck Minimum Wage:
            <br />
            Elite Crypto Investment & Collaboration Network
            <span className="uk-text-yellow">
              <Typewriter
                wrapperClassName="uk-text-yellow"
                options={{
                  strings: [
                    "Escape the Paycheck Routine",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
          </h1>
          <h3>
          </h3>
          <div className="button-container">         
          </div>
        </div>
      </HeroContainer>
      <SupportContainer>
        <div className="support-container">
          <div className="support-header-container">
            <h2>
            <strong>Introducing Fck Minimum Wage | FMW{" "}
            <span className="uk-text-orange">
              <Typewriter
                wrapperClassName="uk-text-orange"
                options={{
                  strings: [
                    "A Revolution in the Crypto World",
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </span>
            </strong>
            </h2>
          </div>
          <div className="support-img-container">
            <div className="support-img-wrapper">
              <img src={supportImg} alt="Logo" />
            </div>
          </div>
          <div className="support-copy-container">
            <h3 className="uk-text-bold">
            Introducing FMW: Your Gateway to the <span className="uk-text-orange">Future</span>
            </h3>
            <p>
            FMW is not just a group; it's a powerhouse of digger builders, uniting experts from every domain in the crypto ecosystem.
            </p>
            <p>At FMW, we're more than a team – we're a family of crypto enthusiasts, driven by passion and a shared vision. Here, you'll find the alpha you need, the insider insights that make the difference, and a community that supports each other every step of the way.
            </p>
            <p>
              Join FMW and be a part of a dynamic, collaborative DAO where your skills can shine and your crypto journey can soar. Don't just watch the revolution; be a part of it with FMW!
            </p>
            <h4 className="uk-text-bold">
            Join our DAO today and tap into a world of exclusive <span className="uk-text-orange">crypto insights!</span>
            </h4>
            <div className="button-container">
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="uk-button uk-button-primary uk-button-large uk-text-bold">
                  Join Us Now!
                </button>
              </a>
            </div>
          </div>
        </div>
      </SupportContainer>
      <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
      <BenefitsContainer>
        <div className="header-container">
          <h2>
              We aim to drive talent and projects forward. Learn with us and help us build the future. We are {" "}
            <span className="text-bold">FMW Capital</span>.
          </h2>
        </div>
        <div
          className="benefits-cards-container"
          uk-height-match="target: > div > .uk-card"
        >
          <div className="card-container">
            <div className="uk-card uk-card-default uk-card-body">
              <div className="card-img-wrapper">
                <img src={learnImg} alt="Learn while you are playing." />
              </div>
              <div className="card-text-wrapper">
                <h3 className="uk-card-title uk-text-orange uk-text-bold">
                Connect
                </h3>
                <p>
                Meet other people who are involved in Web3, or just starting out. Discover new projects, communities, and opportunities.
                </p>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="uk-card uk-card-default uk-card-body">
              <div className="card-img-wrapper">
                <img src={connectImg} alt="Learn while you are playing." />
              </div>
              <div className="card-text-wrapper">
                <h3 className="uk-card-title uk-text-orange uk-text-bold">
                 Contribute
                </h3>
                <p>
                You have talents that can greatly enrich our DAO. Skills are acquired through practice and motivation. Learn and contribute with us.
                </p>
              </div>
            </div>
          </div>
          <div className="card-container">
            <div className="uk-card uk-card-default uk-card-body">
              <div className="card-img-wrapper">
                <img src={earnImg} alt="Learn while you are playing." />
              </div>
              <div className="card-text-wrapper">
                <h3 className="uk-card-title uk-text-orange uk-text-bold">
                 Build
                </h3>
                <p>
                We focus on innovative and useful projects. Our goal is to solve real-world problems to build a better future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </BenefitsContainer>
      <FeaturesContainer>
        <div className="support-container">
          <div className="support-header-container">
            <h2>
               Grow the Community <br/>
              <span className="uk-text-orange uk-text-bold">Together</span>!
            </h2>
          </div>
          <div className="support-copy-container">
          <br></br><br></br><br></br><br></br><br></br>
            <p>What are the benefits of joining <span className="uk-text-orange uk-text-bold">FMW Capital</span>?</p>
              <ul className="uk-list uk-list-square uk-margin-remove">
              <li><strong>Access to Exclusive Information:</strong> Joining our DAO grants you access to cutting-edge information and market analysis in the cryptocurrency space, unavailable to the general public.</li>
              <li><strong>Supportive and Collaborative Network:</strong> Become part of a close-knit community of cryptocurrency enthusiasts, offering support, advice, and collaborative opportunities.</li>
              <li><strong>Involvement in Innovative Projects:</strong> Our DAO is engaged in various innovative projects in the crypto space, providing members with opportunities to contribute and grow professionally.</li>
              <li><strong>Sharing of Resources and Opportunities:</strong> Members enjoy mutual sharing of resources and potential investment opportunities in the crypto ecosystem.</li>
              <li><strong>Influence on Decisions and Direction:</strong> As a member, you have a say in key decisions and the direction of our DAO, giving you a true sense of belonging and impact.</li>
            </ul>
            <div className="uk-margin-large uk-flex uk-flex-center uk-width-1-1">
    <a href="https://discord.gg/EePCSMDZ3V" target="_blank" rel="noopener noreferrer">
        <button className="uk-button uk-button-primary uk-button-large uk-text-bold">
            Join Us Now!
        </button>
    </a>
</div>

          </div>
          {/* <Link
            to="/contacto"
            className="uk-width-1-1 uk-margin uk-hidden@m uk-flex uk-flex-center"
          >
            <button className="uk-button uk-button-primary uk-button-large uk-text-bold">
              Join
            </button>
          </Link> */}
          <div className="support-img-container">
            <div className="support-img-wrapper">
              <img src={featureImg} alt="Logo" />
            </div>
          </div>
        </div>
      </FeaturesContainer>
    </Fragment>
  );
};

const HeroContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  background-image: linear-gradient(
      to bottom,
      rgba(46, 46, 44, 0.8),
      rgba(105, 113, 76, 0.4)
    ),
    url(${heroImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  div.hero-copy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 15px 120px 15px;
    text-align: center;
    h1.uk-text-bold {
      color: #fafafa;
      font-family: "Maven Pro" !important;
    }
    h1.uk-text-bold > span.uk-text-orange {
      color: #f25b3d;
      font-family: "Maven Pro" !important;
    }
    h3 {
      color: #fafafa;
    }

    div.button-container {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }

    div.button-container > a > button.uk-button.uk-button-large {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      font-weight: bold;
      padding: 0 40px;
    }
    div.button-container > a:hover {
      text-decoration: none;
    }
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    div.hero-copy-container {
      width: 70%;
      text-align: center;
      margin-top: 80px;
    }
    div.hero-copy-container > div.button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
    div.hero-copy-container
      > div.button-container
      > a
      > button.uk-button.uk-button-large {
      font-size: 1.15rem;
    }
  }
  @media screen and (min-width: 1280px) {
    align-items: center;
    div.hero-copy-container {
      width: 50%;
      text-align: center;
      margin-top: 40px;
    }
    div.hero-copy-container > h1 {
      font-size: 3rem;
    }
    div.hero-copy-container > div.button-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
    div.hero-copy-container > div.button-container > button {
      font-size: 1.15rem;
    }
  }
`;

const FeaturesContainer = styled.section`
  width: 100vw;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;

  div.support-container {
    margin-top: 10%;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div.support-container > div.support-header-container {
    text-align: center;
    width: 95%;
  }

  div.support-container > div.support-header-container > h2 {
    color: #2e2e2c;
    margin-bottom: 20px;
  }

  div.support-container > div.support-img-container {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
  div.support-container
    > div.support-img-container
    > div.support-img-wrapper
    > img {
    border-radius: 5%;
    width: 90%;
  }
  div.support-container > div.support-copy-container {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
  }
  div.support-container > div.support-copy-container > p,
  div.support-container > div.support-copy-container > ul > li {
    text-align: left;
    font-size: 1.1rem;
  }

  a > button.uk-button.uk-button-large {
    font-size: 1.1rem;
  }

  @media screen and (min-width: 768px) {
    div.support-container {
      margin-top: 7.5%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;
    }
    .support-header-container {
      width: 100%;
    }
    div.support-container > div.support-img-container {
      width: 60%;
    }
    div.support-container > div.support-copy-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      p {
        width: 80%;
        margin-top: 0;
        font-size: 1.25rem;
      }
    }

    div.support-container > div.support-copy-container > ul {
      margin-left: 40px;
    }
    div.support-container > div.support-img-container {
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 960px) {
    div.support-container {
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;
    }
    div.support-header-container {
      width: 100%;
    }
    div.support-container > div.support-img-container {
      width: 45%;
    }
    div.support-container > div.support-copy-container {
      width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 20px;
      p {
        width: 95%;
        margin-top: 0;
        font-size: 1.25rem;
      }
    }
    div.support-container > div.support-copy-container > ul > li {
      margin-left: 40px;
    }
    div.support-container > div.support-img-container {
      margin-bottom: 20px;
    }
    div.support-container
      > div.support-img-container
      > div.support-img-wrapper
      > img {
      width: 85%;
    }
  }

  @media screen and (min-width: 1280px) {
    min-height: calc(100vh - 64px);
    div.support-container {
      width: 80%;
    }
    div.support-container
      > div.support-img-container
      > div.support-img-wrapper
      > img {
      border-radius: 5%;
      width: 80%;
    }
    div.support-container {
      margin-top: 5%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    div.support-header-container {
      width: 100%;
      margin-bottom: 20px;
    }
    div.support-container > div.support-img-container {
      width: 50%;
      margin-bottom: 0;
    }
    div.support-container > div.support-copy-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        width: 80%;
        margin-top: 0;
        font-size: 1.25rem;
      }
    }
  }
`;

const BenefitsContainer = styled.section`
  width: 100vw;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #2e2e2c;

  div.header-container {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    padding: 0 10px;
    h2 {
      color: #f8f8f8;
    }
    h2 > span.text-bold {
      color: #f36058;
      font-weight: bold;
    }
  }

  div.benefits-cards-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }

  div.benefits-cards-container > div.card-container {
    width: 100%;
    margin-bottom: 20px;
  }

  div.benefits-cards-container
    > div.card-container
    > div.uk-card.uk-card-default.uk-card-body {
    text-align: center;
    border-radius: 10px;
    border: 2px solid #2e1359;
    p {
      margin: 0;
    }
  }
  div.benefits-cards-container
    > div.card-container
    > div.uk-card.uk-card-default.uk-card-body
    > div.card-text-wrapper {
    display: flex;
    flex-direction: column;
  }
  div.benefits-cards-container
    > div.card-container
    > div.uk-card.uk-card-default.uk-card-body
    > div.card-text-wrapper
    > h3 {
    margin-top: 20px;
    color: #4e2097;
  }
  div.benefits-cards-container
    > div.card-container
    > div.uk-card.uk-card-default.uk-card-body
    > div.card-text-wrapper
    > p {
    color: #333;
  }
  div.benefits-cards-container
    > div.card-container
    > div.uk-card.uk-card-default.uk-card-body
    > div.card-img-wrapper {
    display: flex;
    justify-content: center;
  }
  div.benefits-cards-container
    > div.card-container
    > div.uk-card.uk-card-default.uk-card-body
    > div.card-img-wrapper
    > img {
    max-width: 25%;
  }

  @media screen and (min-width: 768px) {
    div.header-container {
      margin: 60px 0 20px 0;
    }
    div.benefits-cards-container
      > div
      > div.uk-card.uk-card-default.uk-card-body {
      border: 2px solid red;
    }

    div.benefits-cards-container
      > div.card-container
      > div.uk-card.uk-card-default.uk-card-body
      > div.card-img-wrapper
      > img {
      max-width: 15%;
    }
  }
  @media screen and (min-width: 960px) {
    div.header-container {
      width: 80%;
      margin: 60px 0 0px 0;
    }
    div.benefits-cards-container {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    div.benefits-cards-container > div.card-container {
      width: 30%;
      margin-bottom: 40px;
      height: 1;
    }

    div.benefits-cards-container
      > div.card-container
      > div.uk-card.uk-card-default.uk-card-body
      > div.card-img-wrapper
      > img {
      max-width: 40%;
    }
  }
`;

const SupportContainer = styled.section`
  width: 100vw;
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;

  div.support-container {
    margin-top: 10%;
    width: 90%;
    text-align: center;
  }
  div.support-container > div.support-header-container {
    text-align: center;
    width: 95%;
  }

  div.support-container > div.support-header-container > h2 {
    color: #2e2e2c;
    margin-bottom: 20px;
  }

  div.support-container > div.support-img-container {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  div.support-container
    > div.support-img-container
    > div.support-img-wrapper
    > img {
    border-radius: 5%;
    width: 75%;
  }
  div.support-container > div.support-copy-container > h4 {
    margin-top: 10px;
  }
  div.support-container > div.support-copy-container > p {
    text-align: center;
    font-size: 1.1rem;
  }
  div.support-container > div.support-copy-container > div.button-container {
    display: flex;
    justify-content: center;
    font-size: 1.1rem;

    button.uk-button.uk-button-large {
      font-size: 1.1rem;
    }
    margin: 40px 0 40px 0;
  }

  @media screen and (min-width: 768px) {
    div.support-container {
      height: 100%;
      margin-top: 5%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    .support-header-container {
      width: 100%;
      margin-bottom: 20px;
    }
    div.support-container > div.support-img-container {
      margin-bottom: 40px;
    }
    div.support-container
      > div.support-img-container
      > div.support-img-wrapper
      > img {
      width: 50%;
    }
  }
  @media screen and (min-width: 960px) {
    div.support-container {
      width: 90%;
    }
    div.support-container
      > div.support-img-container
      > div.support-img-wrapper
      > img {
      border-radius: 5%;
      margin: 20px 0;
      width: 80%;
    }
    div.support-container {
      margin-top: 5%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 40px;
    }
    div.support-header-container {
      width: 100%;
      margin-bottom: 20px;
    }
    div.support-container > div.support-img-container {
      width: 50%;
      margin-bottom: 0;
    }
    div.support-container > div.support-copy-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        width: 80%;
        margin-top: 0;
        font-size: 1.25rem;
      }
    }

    div.support-container > div.support-copy-container > div.button-container {
      margin: 20px 0 40px 0;
    }
  }
  @media screen and (min-width: 1280px) {
    height: calc(100vh - 64px);
    div.support-container {
      width: 80%;
    }
  }
`;

const FooterContainer = styled.section`
  display: flex;
  align-items: center;
  padding: 20px 15px;
  background-color: #2e2e2c;
  color: #f6f2e6;
  a {
    color: #f6f2e6;
  }
  .text-container,
  .social-icons {
    width: 50%;
    display: flex;
  }
  .text-container {
    justify-content: start;
  }

  .social-icons {
    justify-content: space-around;
  }

  @media screen and (min-width: 768px) {
    .social-icons {
      justify-content: end;
    }
    .social-icons > * {
      margin-right: 20px;
      display: flex;
      align-items: center;
    }
  }
`;

export default Landing;
